var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('ControlNavbar'),(_vm.application)?_c('page-header',{attrs:{"title":_vm.$route.params.application.label || this.$session.get('backup').label}}):_vm._e(),_c('MyBanner',{attrs:{"banner":_vm.$route.params.application.banner || this.$session.get('backup').banner}}),_c('br'),(_vm.application)?_c('div',{staticClass:"container"},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 12 }},on:{"submit":_vm.handleSubmit}},[_vm._l((_vm.application.data.fields),function(field,index){return _c('div',{key:index},[(field.type === 'text')?_c('a-form-item',{attrs:{"label":field.title}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              ("" + (field.id)),
              {
                rules: [
                  { required: field.required, message: ((field.title) + "!") } ],
              } ]),expression:"[\n              `${field.id}`,\n              {\n                rules: [\n                  { required: field.required, message: `${field.title}!` },\n                ],\n              },\n            ]"}],on:{"change":function (e) {
                _vm.calculation[field.id] = e.target.value;
              }}})],1):(field.type === 'image')?_c('a-form-item',{attrs:{"label":field.title,"required":field.required}},[_c('image-uploader',{attrs:{"fieldData":{
              userId: _vm.$session.get('userId'),
              appId: _vm.application.data.id,
              data: field,
            },"min":field.min || 1,"max":field.max || 1,"deletable":field.deletable || true},on:{"imageUpload":function($event){return _vm.imageUpload($event, field.id)}}})],1):(field.type === 'calculation')?_c('a-form-item',{attrs:{"label":field.title}},[_c('p',{attrs:{"id":field.id}},[_vm._v(" "+_vm._s(_vm.calculate(field.data, field.id))+_vm._s(_vm._f("numeral")((_vm.calculation[field.id] || JSON.parse(field.data).error),JSON.parse(field.data).format.replace("#", "0")))+" "+_vm._s(_vm.$session.get("currency"))+" ")])]):(field.type === 'equation')?_c('a-form-item',{attrs:{"label":field.title}},[_c('p',{attrs:{"id":field.id}},[_vm._v(" "+_vm._s(_vm.getEquationAmount(field.id, JSON.parse(field.data).error))+_vm._s(JSON.parse(field.data).error)+" ")])]):(field.type === 'dsl.plan')?_c('a-form-item',{attrs:{"label":field.title}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              ("" + (field.id)),
              {
                rules: [
                  {
                    required: field.required,
                    message: JSON.parse(field.data).prompt,
                  } ],
              } ]),expression:"[\n              `${field.id}`,\n              {\n                rules: [\n                  {\n                    required: field.required,\n                    message: JSON.parse(field.data).prompt,\n                  },\n                ],\n              },\n            ]"}],attrs:{"placeholder":field.title}},_vm._l((JSON.parse(field.data).objects),function(obj,index){return _c('a-select-option',{key:index,attrs:{"value":obj.value}},[_vm._v(" "+_vm._s(obj.cost)+" "+_vm._s(obj.speed)+" "+_vm._s(obj.limit)+" ")])}),1)],1):(field.type === 'spinner')?_c('a-form-item',{attrs:{"label":field.title}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              ("" + (field.id)),
              {
                rules: [
                  {
                    required: field.required,
                    message: JSON.parse(field.data).prompt,
                  } ],
              } ]),expression:"[\n              `${field.id}`,\n              {\n                rules: [\n                  {\n                    required: field.required,\n                    message: JSON.parse(field.data).prompt,\n                  },\n                ],\n              },\n            ]"}],attrs:{"placeholder":field.title}},_vm._l((JSON.parse(field.data).objects),function(obj,index){return _c('a-select-option',{key:index,attrs:{"value":obj.value}},[_vm._v(" "+_vm._s(obj.text)+" ")])}),1)],1):(field.type === 'label')?_c('div',[_c('p',{style:(("color: #" + (JSON.parse(field.data).color) + ";"))},[_vm._v(" "+_vm._s(field.title)+" ")])]):_vm._e()],1)}),_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 6 }}},[_c('a-button',{attrs:{"type":"danger","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.application.data.submit)+" ")])],1)],2)],1):_vm._e(),(_vm.actions && _vm.actions.length > 0)?_c('a-modal',{attrs:{"footer":null},model:{value:(_vm.actionsVisible),callback:function ($$v) {_vm.actionsVisible=$$v},expression:"actionsVisible"}},_vm._l((_vm.actions),function(action,index){return _c('div',{key:index,staticClass:"whish-button"},[(action.type == 'sms')?_c('a',{attrs:{"target":"_blank","href":("sms:" + (action.to) + "&body=" + (action.text))}},[_vm._v(" "+_vm._s(action.button)+" ")]):(action.type == 'whatsapp')?_c('a',{attrs:{"target":"_blank","href":("https://wa.me/" + (action.to.replace(
          '+',
          ''
        )) + "?text=" + (action.text.replace(' ', '%20')))}},[_vm._v(" "+_vm._s(action.button)+" ")]):_vm._e()])}),0):_vm._e(),_c('loading',{attrs:{"loading":_vm.loader}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }