<template>
  <div class="">
    <ControlNavbar/>
    <page-header
        :title="
        $route.params.application.label || this.$session.get('backup').label
      "
        v-if="application"
    />
    <MyBanner
        :banner="
        $route.params.application.banner || this.$session.get('backup').banner
      "
    />
    <!-- <h1>need to support images</h1> -->
    <!-- {{ application.data.fields[0].label }} -->
    <br/>
    <div class="container" v-if="application">
      <!-- {{ application.data.fields[0].label }} -->
      <a-form
          :form="form"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 12 }"
          @submit="handleSubmit"
      >
        <div v-for="(field, index) in application.data.fields" :key="index">
          <a-form-item :label="field.title" v-if="field.type === 'text'">
            <a-input
                @change="
                (e) => {
                  calculation[field.id] = e.target.value;
                }
              "
                v-decorator="[
                `${field.id}`,
                {
                  rules: [
                    { required: field.required, message: `${field.title}!` },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item
              :label="field.title"
              :required="field.required"
              v-else-if="field.type === 'image'"
          >
            <image-uploader
                :fieldData="{
                userId: $session.get('userId'),
                appId: application.data.id,
                data: field,
              }"
                :min="field.min || 1"
                :max="field.max || 1"
                :deletable="field.deletable || true"
                @imageUpload="imageUpload($event, field.id)"
            />
          </a-form-item>
          <a-form-item
              :label="field.title"
              v-else-if="field.type === 'calculation'"
          >
            <!-- <a-input
              :disabled="true"
              :value="calculation[field.id] || JSON.parse(field.data).error"
              v-model="calculation[field.id]"
            /> -->

            <p :id="field.id">
              {{
                calculate(field.data, field.id)
              }}{{
                (calculation[field.id] || JSON.parse(field.data).error)
                    | numeral(JSON.parse(field.data).format.replace("#", "0"))
              }}
              {{ $session.get("currency") }}
            </p>
          </a-form-item>
          <a-form-item
              :label="field.title"
              v-else-if="field.type === 'equation'"
          >
            <!-- <a-input
              :disabled="true"
              :value="calculation[field.id] || JSON.parse(field.data).error"
              v-model="calculation[field.id]"
            /> -->

            <p :id="field.id">
              {{
                getEquationAmount(field.id, JSON.parse(field.data).error)
              }}{{ JSON.parse(field.data).error }}
            </p>
          </a-form-item>
          <a-form-item
              :label="field.title"
              v-else-if="field.type === 'dsl.plan'"
          >
            <a-select
                v-decorator="[
                `${field.id}`,
                {
                  rules: [
                    {
                      required: field.required,
                      message: JSON.parse(field.data).prompt,
                    },
                  ],
                },
              ]"
                :placeholder="field.title"
            >
              <a-select-option
                  v-for="(obj, index) in JSON.parse(field.data).objects"
                  :key="index"
                  :value="obj.value"
              >
                {{ obj.cost }} {{ obj.speed }} {{ obj.limit }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
              :label="field.title"
              v-else-if="field.type === 'spinner'"
          >
            <a-select
                v-decorator="[
                `${field.id}`,
                {
                  rules: [
                    {
                      required: field.required,
                      message: JSON.parse(field.data).prompt,
                    },
                  ],
                },
              ]"
                :placeholder="field.title"
            >
              <a-select-option
                  v-for="(obj, index) in JSON.parse(field.data).objects"
                  :key="index"
                  :value="obj.value"
              >
                {{ obj.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <div v-else-if="field.type === 'label'">
            <p :style="`color: #${JSON.parse(field.data).color};`">
              {{ field.title }}
            </p>
          </div>
        </div>
        <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
          <a-button type="danger" html-type="submit">
            {{ application.data.submit }}
          </a-button>
        </a-form-item>
      </a-form>
    </div>
    <a-modal
        v-model="actionsVisible"
        v-if="actions && actions.length > 0"
        :footer="null"
    >
      <div v-for="(action, index) in actions" :key="index" class="whish-button">
        <a
            target="_blank"
            :href="`sms:${action.to}&body=${action.text}`"
            v-if="action.type == 'sms'"
        >
          {{ action.button }}
        </a>
        <a
            target="_blank"
            :href="`https://wa.me/${action.to.replace(
            '+',
            ''
          )}?text=${action.text.replace(' ', '%20')}`"
            v-else-if="action.type == 'whatsapp'"
        >
          {{ action.button }}
        </a>
      </div>
    </a-modal>
    <loading :loading="loader"/>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
// @ is an alias to /src
import ControlNavbar from "@/components/ControlNavbar";
import Loading from "./Loading.vue";

export default {
  name: "SpecificApplication",
  components: {ControlNavbar, Loading},
  data() {
    return {
      loader: false,
      calculation: [], //calculation array is an array with the input ids as key with their values, used to complete submit form values for the api
      application: null,
      formLayout: "horizontal",
      form: this.$form.createForm(this, {name: "applicationForm"}),
      images: {},
      actions: null,
      actionsVisible: false,
    };
  },
  methods: {
    imageUpload(images, fieldId) {
      this.images[`${fieldId}`] = [];
      images.forEach((img) => {
        this.images[`${fieldId}`].push(img.split("base64,")[1]);
      });

      console.log(this.images);
    },
    setLoader(show) {
      this.loader = show;
    },
    getAxios() {
      return axios.create({
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          language: this.$session.get("lang") || "en",
          itelVersion: "1000",
          uid: "uidtest1.3",
          brand: "Web",
          model: "Web",
          osVersion: "unix",
          operatingSystem: "4",
          sessionId: `${this.$session.get("sessionId")}`,
          token: `${this.$session.get("token")}`,
          deviceId: `${this.$session.get("deviceid")}`,
          sessionCounter: `${this.$session.get("sessionCounter") || 0}`,
          counter: `${this.$session.get("counter")}`,
        },
      });
    },
    showActions(actions) {
      this.actions = actions;
      this.actionsVisible = true;
    },
    success(title, message, actions) {
      console.log(actions);
      let self = this;
      this.$success({
        title: title,
        // JSX support
        content: (
            <div>
              <p>{message}</p>
            </div>
        ),
        onOk() {
          self.showActions(actions);
        },
      });
    },
    handleSubmit(e) {
      var fieldValues = [];
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
          this.application.data.fields.forEach((field) => {
            if (field.type == "calculation") {
              fieldValues.push({
                fieldId: field.id,
                stringValue: this.calculation[field.id],
                imageValues: [],
              });
            } else if (field.type === "image") {
              fieldValues.push({
                fieldId: field.id,
                stringValue: null,
                imageValues: this.images[`${field.id}`],
              });
            } else if (field.type === "text") {
              let input = document.getElementById(
                  `applicationForm_${field.id}`
              );
              fieldValues.push({
                fieldId: field.id,
                stringValue: input.value,
                imageValues: [],
              });
            } else if (field.type === "dsl.plan") {
              fieldValues.push({
                fieldId: field.id,
                stringValue: values[`${field.id}`],
                imageValues: [],
              });
            } else if (field.type === "spinner") {
              fieldValues.push({
                fieldId: field.id,
                stringValue: values[`${field.id}`],
                imageValues: [],
              });
            }
          });
          console.log("fieldValues", fieldValues);
          this.setLoader(true);
          this.getAxios()
              .post(`${this.$session.get("baseURI")}application/submit`, {
                applicationId: this.application.data.id,
                deletedImages: [],
                applicationFieldSubmission: fieldValues,
              })
              .then((result) => {
                this.setLoader(false);
                if (this.checkApiRes(result, values, "application/submit", "post")) {
                  // ok
                  console.log("ok", result);
                  this.success(
                      result.data.dialog.title,
                      result.data.dialog.message,
                      result.data.actions
                  );
                }
              });
        }
      });
    },
    updateValues(e) {
      console.log(e.target.value);
    },
    calculate(equation, id) {
      //works only with 2 part equation with * or + or - or /
      // calculation[id]
      equation = JSON.parse(equation);
      var variables;
      if (equation.equation.includes("*")) {
        variables = equation.equation.split("*");
        variables[0] = variables[0].trim();
        variables[1] = variables[1].trim();
        equation.variablesnew.forEach((newvar) => {
          if (newvar[variables[0]]) {
            // newvar[variables[0]]
            equation.constants.forEach((constant) => {
              if (constant[variables[1]]) {
                console.log(
                    parseFloat(this.calculation[newvar[variables[0]]]),
                    parseFloat(constant[variables[1]])
                );
                var a =
                    parseFloat(this.calculation[newvar[variables[0]]]) *
                    parseFloat(constant[variables[1]]);
                this.calculation[id] = a;
              }
            });
          } else if (newvar[variables[1]]) {
            equation.constants.forEach((constant) => {
              if (constant[variables[0]]) {
                console.log(
                    parseFloat(newvar[variables[1]]),
                    parseFloat(constant[variables[0]])
                );
                this.calculation[id](
                    parseFloat(this.calculation[newvar[variables[1]]]) *
                    parseFloat(constant[variables[0]])
                );
              }
            });
          }
        });
      } else if (equation.equation.includes("+")) {
        variables = equation.equation.split("+");
        variables[0] = variables[0].trim();
        variables[1] = variables[1].trim();
        equation.variablesnew.forEach((newvar) => {
          if (newvar[variables[0]]) {
            // newvar[variables[0]]
            equation.constants.forEach((constant) => {
              if (constant[variables[1]]) {
                var a =
                    parseFloat(this.calculation[newvar[variables[0]]]) +
                    parseFloat(constant[variables[1]]);
                this.calculation[id] = a;
              }
            });
          } else if (newvar[variables[1]]) {
            equation.constants.forEach((constant) => {
              if (constant[variables[0]]) {
                this.calculation[id](
                    parseFloat(this.calculation[newvar[variables[1]]]) +
                    parseFloat(constant[variables[0]])
                );
              }
            });
          }
        });
      } else if (equation.equation.includes("-")) {
        variables = equation.equation.split("-");
        variables[0] = variables[0].trim();
        variables[1] = variables[1].trim();
        equation.variablesnew.forEach((newvar) => {
          if (newvar[variables[0]]) {
            // newvar[variables[0]]
            equation.constants.forEach((constant) => {
              if (constant[variables[1]]) {
                var a =
                    parseFloat(this.calculation[newvar[variables[0]]]) -
                    parseFloat(constant[variables[1]]);
                this.calculation[id] = a;
              }
            });
          } else if (newvar[variables[1]]) {
            equation.constants.forEach((constant) => {
              if (constant[variables[0]]) {
                this.calculation[id](
                    parseFloat(this.calculation[newvar[variables[1]]]) -
                    parseFloat(constant[variables[0]])
                );
              }
            });
          }
        });
      } else if (equation.equation.includes("/")) {
        variables = equation.equation.split("/");
        variables[0] = variables[0].trim();
        variables[1] = variables[1].trim();
        equation.variablesnew.forEach((newvar) => {
          if (newvar[variables[0]]) {
            // newvar[variables[0]]
            equation.constants.forEach((constant) => {
              if (constant[variables[1]]) {
                var a =
                    parseFloat(this.calculation[newvar[variables[0]]]) /
                    parseFloat(constant[variables[1]]);
                this.calculation[id] = a;
              }
            });
          } else if (newvar[variables[1]]) {
            equation.constants.forEach((constant) => {
              if (constant[variables[0]]) {
                this.calculation[id](
                    parseFloat(this.calculation[newvar[variables[1]]]) /
                    parseFloat(constant[variables[0]])
                );
              }
            });
          }
        });
      }
    },
    getEquationAmount(equationid, equationError) {
      let fieldValues = [];
      let inputs = document.getElementsByTagName("input");
      inputs.forEach((input) => {
        fieldValues.push({
          fieldId: input.id.split("_")[1],
          stringValue: input.value,
          imageValues: [],
        });
      });
      this.application.data.fields.forEach((field) => {
        if (field.type == "calculation") {
          fieldValues.push({
            fieldId: field.id,
            stringValue: this.calculation[field.id],
            imageValues: [],
          });
        }
      });
      this.setLoader(true);
      this.getAxios()
          .post(`${this.$session.get("baseURI")}application/equation`, {
            applicationId: this.application.data.id,
            applicationFieldSubmission: fieldValues,
            equationId: equationid,
          })
          .then((result) => {
            this.setLoader(false);
            if (result.data.status) {
              this.calculation[equationid] = result.data.data.text;
              if (result.data.data.text == "N/A") {
                this.calculation[equationid] = equationError;
              }
              document.getElementById(equationid).innerHTML =
                  this.calculation[equationid];
            }
          });
    },
  },
  computed: {},
  created() {
    console.log(
        "application: ",
        this.$route.params.application || this.$session.get("backup")
    );
    let basincinfo;
    if (this.$route.params.application) {
      this.$session.set("backup", this.$route.params.application);
      basincinfo = this.$route.params.application;
    } else {
      basincinfo = this.$session.get("backup");
      this.$route.params.application = this.$session.get("backup");
    }
    if (basincinfo) {
      this.setLoader(true);
      this.getAxios()
          .post(`${this.$session.get("baseURI")}application/get`, {
            applicationId: basincinfo.data.id,
          })
          .then((result) => {
            this.setLoader(false);
            if (this.checkApiRes(result, {
              applicationId: basincinfo.data.id,
            }, "application/get", "post")) {
              // ok
              console.log("ok", result);
              let tempApp = result.data;
              let tempOrdered = [];
              tempApp.data.fields.forEach((element) => {
                tempOrdered.push(Number(element.index));
              });
              tempOrdered.sort(function (a, b) {
                return b - a;
              });
              console.log("sorted", tempOrdered);
              tempOrdered.reverse();
              console.log("reversed", tempOrdered);

              tempApp.data.fields.forEach((element) => {
                for (let i = 0; i < tempOrdered.length; i++) {
                  if (element.index == tempOrdered[i] + "") {
                    tempOrdered[i] = element;
                  }
                }
                // tempOrdered[element.index / 1000 - 1] = element;
              });
              console.log("res.dta", result.data);
              this.$route.params.application.data.fields = tempOrdered;
              result.data.data.fields = tempOrdered;
              this.application = result.data;
              console.log("fields: ", tempOrdered);
            }
          });
    }
  },
};
</script>

<style lang="scss" scoped>
.whish-button {
  text-align: center;
  padding: 10px;
  background: red;

  a {
    color: white;
  }

  a:hover {
    text-decoration: underline;
  }

  margin: 5%;
}
</style>
