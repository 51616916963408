<template>
  <nav class="navbar navbar-expand-sm">
    <div class="container-fluid">
      <div class="back col-2">
        <div @click="$router.go(-1)">
          <a-icon type="left" v-if="$session.get('lang') != 'ar'" />
          <a-icon type="right" v-else-if="$session.get('lang') == 'ar'" />
        </div>
      </div>
      <div class="whish-logo col-8">
        <router-link to="/Home">
          <img
            class="navbar-brand"
            src="../assets/whish-white-logo_v2.png"
            alt="WHISH LOGO"
            style="width: 31%"
          />
        </router-link>
      </div>
      <div class="col-2"></div>
    </div>
  </nav>
</template>

<script>
// import 'bootstrap'
// import 'bootstrap/dist/css/bootstrap.min.css'
export default {
  name: "ControlNavbar",
  components: {},
  props: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.back * {
  color: white;
  font-size: xx-large;
}
.back {
  text-align: start;
}
nav {
  background-color: #e62946;

  router-link {
    color: white;
  }
  .dropdown {
    img {
      max-width: 40px;
    }
    width: 25%;
    text-align: start;
  }
  .profile {
    img {
      max-width: 40px;
    }
    width: 25%;
    text-align: end;
  }
  .whish-logo {
    img {
      max-width: 111px;
    }
    width: 100%;
    text-align: center;
  }
}
</style>
